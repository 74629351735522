import React, { useState, useEffect } from "react";
import "./DonateImage.css";

function DonateImage() {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Add the show class after the component is mounted
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 10); // Delay to ensure the transition is visible

    return () => clearTimeout(timer); // Clean up the timer
  }, []);
  return (
    <>
      <img
        className={`backgroundImage fade-in ${isVisible ? "show" : ""}`}
        src="https://images.pexels.com/photos/1178562/pexels-photo-1178562.jpeg"
        alt="donate_image"
      />
      <h1 className="overlayTextCenter">Donate</h1>
    </>
  );
}

export default DonateImage;
