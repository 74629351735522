import React from "react";
import "../../App.css";
import "../Title.css";
import ContactForm from "../ContactForm";
import ContactImage from "../ContactImage";
import ContactIntro from "../ContactIntro";

function Contact() {
  return (
    <>
      <ContactImage />
      <ContactIntro />
      <ContactForm />
    </>
  );
}

export default Contact;
