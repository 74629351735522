import React from "react";
import "./About.css";

function About() {
  return (
    <>
      {/* <div className="abtBox">
        <div className="abtLeft">
          <div className="lHead">WHO WE ARE</div>
          <div className="lMotto">Dedicated to Education.</div>
          <div className="lText">
            American Culture and Education Council (ACEC) is a non-profit
            organization founded by an American Pastor David in 2012. Its main
            mission is to facilitate foreign students from the Greater
            Washington area who are new to the United States, help them adapt to
            their student life in the United States as soon as possible, and
            share the love and gospel of Jesus Christ with them. Most of the
            foreign students are from mainland China.
          </div>
          <div className="lText">
            Several of our co-workers took over ACEC after Pastor David retired
            in 2015. The ministry has focused on the development of online
            evangelism and gospel video activities. Several local churches have
            supported the operations and mission.
          </div>
          <div className="lText">
            The video ministry content mainly includes the following three
            programs:
            <ol>1) Christian life growing testimony</ol>
            <ol>2) Interpretation of community news</ol>
            <ol>3) Christian View on News</ol>
          </div>
          <div className="lText">
            All videos are mainly disseminated through the YouTube channel. The
            audience is mainly overseas Chinese. The channel name is "ACEC
            Christians View on News". Currently, there are nearly 800
            subscribers, and a single video has a maximum of over 13,000 views.
          </div>
          <div className="lText">
            ACEC's video programs are authorized to publish on WeChat official
            channels via three Chinese Christian conservative content accounts
            as "Xiao Canke", "Xiao Shengke" and "The Protagonist in Life". The
            audience is mainly Chinese in mainland China, and the video click
            rate is about eight thousand on average.
          </div>
          <div className="lText">
            All co-workers are unpaid volunteers from church. There are over 120
            original videos that have been shot since 2019. We are grateful for
            the many Brothers/Sisters who support ACEC through volunteer
            involvement and financial contributions. In addition to our ministry
            for over 5 years, 100% of all contributions given to our team have
            gone to our three programs shown above. Please continue to donate
            and join us.
          </div>
          <div className="lText">
            "Go into all the world and preach the good news to all creation." -
            Mark 16:15{" "}
          </div>
        </div>
        <div className="abtRight"></div>
      </div> */}
      <div className="contentA">
        <div className="aboutSection">
          <h1>About Us</h1>
          <p>
            American Culture and Education Council (ACEC) is a non-profit
            organization founded by an American Pastor David in 2012. Its main
            mission is to facilitate foreign students from the Greater
            Washington area who are new to the United States, help them adapt to
            their student life in the United States as soon as possible, and
            share the love and gospel of Jesus Christ with them. Most of the
            foreign students are from mainland China.
          </p>
          <p>
            Several of our co-workers took over ACEC after Pastor David retired
            in 2015. The ministry has focused on the development of online
            evangelism and gospel video activities. Several local churches have
            supported the operations and mission.
          </p>
          <p>
            The video ministry content mainly includes the following three
            programs:
            <ol>1) Christian life growing testimony</ol>
            <ol>2) Interpretation of community news</ol>
            <ol>3) Christian View on News</ol>
          </p>
          <p>
            All videos are mainly disseminated through the YouTube channel. The
            audience is mainly overseas Chinese. The channel name is "ACEC
            Christians View on News". Currently, there are nearly 800
            subscribers, and a single video has a maximum of over 13,000 views.
          </p>
          <p>
            ACEC's video programs are authorized to publish on WeChat official
            channels via three Chinese Christian conservative content accounts
            as "Xiao Canke", "Xiao Shengke" and "The Protagonist in Life". The
            audience is mainly Chinese in mainland China, and the video click
            rate is about eight thousand on average.
          </p>
          <p>
            All co-workers are unpaid volunteers from church. There are over 120
            original videos that have been shot since 2019. We are grateful for
            the many Brothers/Sisters who support ACEC through volunteer
            involvement and financial contributions. In addition to our ministry
            for over 5 years, 100% of all contributions given to our team have
            gone to our three programs shown above. Please continue to donate
            and join us.
          </p>
          <p>
            "Go into all the world and preach the good news to all creation." -
            Mark 16:15{" "}
          </p>
        </div>
      </div>
    </>
  );
}

export default About;
