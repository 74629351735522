import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import "./DonationBox.css";
import jsPDF from "jspdf";

const DonationBox = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showReceiptBox, setShowReceiptBox] = useState(false);
  const [receiptData, setReceiptData] = useState(null);

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://jq4hue0wef.execute-api.us-east-1.amazonaws.com/default/HelloWorld",
        data
      );
      //console.log("Response:", response.data);
      setShowReceiptBox(true);
      setReceiptData({ formData: data });
    } catch (error) {
      console.error("There was an error submitting the form:", error);
    }
  };

  const generateReceipt = (formData, transactionData) => {
    const doc = new jsPDF();

    // Title
    doc.setFontSize(20);
    doc.text("ACEC Donation Receipt", 105, 20, null, null, "center");

    // Drawing a box around the title
    doc.rect(15, 10, 180, 20);

    // Receipt Details
    doc.setFontSize(12);
    doc.text("Name:", 20, 40);
    doc.text(`${formData.firstName} ${formData.lastName}`, 60, 40);

    doc.text("Email:", 20, 50);
    doc.text(formData.email, 60, 50);

    doc.text("Donation Amount:", 20, 60);
    doc.text(`$${formData.donationAmount}`, 60, 60);

    doc.text("Date:", 20, 80);
    doc.text(new Date().toLocaleString(), 60, 80);

    // Billing Address Box
    doc.setFontSize(14);
    doc.text("Billing Address:", 20, 100);
    doc.setFontSize(12);
    doc.rect(15, 90, 180, 40); // Draw a rectangle around the billing address
    doc.text(formData.billingAddress, 20, 110);
    doc.text(
      `${formData.city}, ${formData.state} ${formData.zipCode}`,
      20,
      120
    );

    // Drawing a line separator
    doc.setDrawColor(0, 0, 0);
    doc.line(15, 140, 195, 140);

    // Donation Details Table
    doc.setFontSize(14);
    doc.text("Donation Details:", 20, 150);
    doc.setFontSize(12);

    // Headers
    doc.text("Description", 20, 160);
    doc.text("Amount", 160, 160);

    // Table Content
    doc.text("Donation", 20, 170);
    doc.text(`$${formData.amount}`, 160, 170);

    // Footer Note
    doc.setFontSize(10);
    doc.text(
      "Thank you for your generous donation! Please keep this receipt for your records.",
      20,
      200
    );

    // Save the PDF
    doc.save("receipt.pdf");
  };

  const downloadReceipt = () => {
    if (receiptData) {
      generateReceipt(receiptData.formData, receiptData.transactionData);
    }
  };

  return (
    <div className="donation-box">
      <h2>Make a Donation</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="form-group">
            <label>First Name:</label>
            <input {...register("firstName", { required: true })} />
            {errors.firstName && <span>This field is required</span>}
          </div>
          <div className="form-group">
            <label>Last Name:</label>
            <input {...register("lastName", { required: true })} />
            {errors.lastName && <span>This field is required</span>}
          </div>
        </div>

        <div className="form-group">
          <label>Email:</label>
          <input type="email" {...register("email", { required: true })} />
          {errors.email && <span>This field is required</span>}
        </div>

        <div className="form-group">
          <input {...register("billingAddress", { required: true })} />
          {errors.billingAddress && <span>This field is required</span>}
        </div>

        <div className="form-group">
          <label>City:</label>
          <input {...register("city", { required: true })} />
          {errors.city && <span>This field is required</span>}
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>State:</label>
            <input {...register("state", { required: true })} />
            {errors.state && <span>This field is required</span>}
          </div>
          <div className="form-group">
            <label>ZIP Code:</label>
            <input {...register("zipCode", { required: true })} />
            {errors.zipCode && <span>This field is required</span>}
          </div>
        </div>

        <div className="form-group">
          <label>Card Number:</label>
          <input {...register("cardNumber", { required: true })} />
          {errors.cardNumber && <span>This field is required</span>}
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Expiration Date (MM/YY):</label>
            <input
              type="text"
              {...register("expirationDate", { required: true })}
            />
            {errors.expirationDate && <span>This field is required</span>}
          </div>
          <div className="form-group">
            <label>CVV:</label>
            <input type="number" {...register("cvv", { required: true })} />
            {errors.cvv && <span>This field is required</span>}
          </div>
          <div className="form-group">
            <label>Donation Amount (USD):</label>
            <input
              type="text"
              {...register("amount", {
                required: true,
                pattern: {
                  value: /^\d+\.\d{2}$/,
                  message: "Donation amount must be in the format 0.00",
                },
              })}
            />
            {errors.amount && <span>{errors.amount.message}</span>}
          </div>
        </div>
        <button type="submit">Submit Donation</button>
      </form>
      {showReceiptBox && (
        <div className="receipt-box">
          Thank you for donating!
          <button onClick={downloadReceipt}>
            Click here to download a receipt
          </button>
        </div>
      )}
    </div>
  );
};

export default DonationBox;
