import React from "react";

const ZelleRedirectButton = () => {
  const handleRedirect = () => {};

  return (
    <button
      onClick={handleRedirect}
      style={{
        padding: "20px 20px",
        backgroundColor: "#F08A4B",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "20px",
        margin: "10px 0px",
      }}
    >
      You can Zelle us at acec77777@gmail.com
    </button>
  );
};

export default ZelleRedirectButton;
