import React, { useState, useEffect } from "react";
import "./ContactImage.css";

function ContactImage() {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Add the show class after the component is mounted
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 10); // Delay to ensure the transition is visible

    return () => clearTimeout(timer); // Clean up the timer
  }, []);
  return (
    <>
      <img
        className={`backgroundImageC fade-in ${isVisible ? "show" : ""}`}
        src="https://images.pexels.com/photos/4195330/pexels-photo-4195330.jpeg?"
        alt="contact_image"
      />
      <h1 className="overlayTextCenter">Contact Us</h1>
    </>
  );
}

export default ContactImage;
