import React from "react";
import Navbar2 from "./components/Navbar2";
import "./App.css";
import Home from "./components/pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Videos from "./components/pages/Videos";
import Contact from "./components/pages/Contact";
import Donate from "./components/pages/Donate";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar2 />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/donate" element={<Donate />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
