import React from "react";
import "./DonateForm.css";
import TabbedBox from "./TabbedBox";

function DonateForm() {
  return (
    <>
      <TabbedBox />
    </>
  );
}

export default DonateForm;
