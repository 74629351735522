import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footBox">
      <div className="footLeft"></div>
      <div className="footRight">&copy; ACEC 2021</div>
    </div>
  );
}

export default Footer;
