import React, { useState, useEffect } from "react";
import { convertXML } from "simple-xml-to-json";
import "./VideoNav.css";

function VideoNav() {
  const [searchQuery, setSearchQuery] = useState("");
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    // Define your S3 bucket URL

    const fetchVideoFiles = async () => {
      try {
        const response = await fetch("https://acecvideos.s3.amazonaws.com/");

        if (!response.ok) {
          throw new Error("Failed to fetch images");
        }

        // Check if response has data and is not empty
        if (response.status === 204) {
          throw new Error("No data found");
        }

        const responseData = await response.text();
        console.log("Response Data:", responseData);
        const parser = new DOMParser();
        const xmlData = parser.parseFromString(responseData, "application/xml");
        console.log("XML Data:", xmlData);

        const jsonData = convertXML(responseData);
        console.log(
          "JSON Data:",
          jsonData.ListBucketResult.children.slice(5)[0].Contents.children[0]
            .Key.content
        );

        const videoUrls = jsonData.ListBucketResult.children
          .slice(5)
          .map((item) => [
            `https://acecvideos.s3.us-east-1.amazonaws.com/${item.Contents.children[0].Key.content}`,
            item.Contents.children[0].Key.content.slice(0, -4),
          ]);

        console.log(videoUrls);
        setVideos(videoUrls);
      } catch (error) {
        console.error("Error fetching video files:", error);
      }
    };

    fetchVideoFiles();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredVideos = videos.filter((video) =>
    video[1].includes(searchQuery)
  );
  return (
    <>
      <div className="content">
        <div className="aboutSection">
          <h1>Our Videos</h1>
          <input
            type="text"
            className="searchBar"
            placeholder="Search for a video..."
            value={searchQuery}
            onChange={handleSearch}
          />
          <div className="videoGrid">
            {filteredVideos.map((video) => {
              console.log("source: " + video[0]);
              return (
                <div key={video[1]} className="videoCard">
                  <video controls>
                    <source src={video[0]} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <h2>{video[1]}</h2>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoNav;
