import React from "react";
import HomeImage from "../HomeImage";
import About from "../About";
import "./Home.css";

function Home() {
  return (
    <div>
      <HomeImage />
      <About />
    </div>
  );
}

export default Home;
