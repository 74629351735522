import React from "react";
import "./ContactIntro.css";

function ContactIntro() {
  return (
    <>
      <div className="ciBox">
        <div className="ciTextBox">
          <div className="ciText">
            Want to learn more about ACEC? We would love to hear from you!
          </div>
        </div>

        <div className="triangle"></div>
      </div>
    </>
  );
}

export default ContactIntro;
