import React from "react";

const PayPalRedirectButton = () => {
  const handleRedirect = () => {
    window.location.href = "http://paypal.me/acec77777";
  };

  return (
    <button
      onClick={handleRedirect}
      style={{
        padding: "20px 20px",
        backgroundColor: "#0070ba",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "20px",
      }}
    >
      Click here to pay with PayPal
    </button>
  );
};

export default PayPalRedirectButton;
