import React, { useState } from "react";
import "./TabbedBox.css";
import "./DonateForm.css";
import DonationBox from "./DonationBox";
import PayPalRedirectButton from "./PayPalRedirectButton";
import ZelleRedirectButton from "./ZelleRedirectButton";

const TabbedBox = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const renderContent = () => {
    switch (activeTab) {
      case "tab1":
        return <DonationBox />;
      case "tab2":
        return (
          <div>
            <PayPalRedirectButton />
            <ZelleRedirectButton />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="content">
      <div className="tabbedBox">
        <div className="tabs">
          <div className="tab" onClick={() => setActiveTab("tab1")}>
            <div
              className={`tabHeader ${activeTab === "tab1" ? "active" : ""}`}
            >
              Credit/Debit
            </div>
          </div>
          <div className="tab" onClick={() => setActiveTab("tab2")}>
            <div
              className={`tabHeader ${activeTab === "tab2" ? "active" : ""}`}
            >
              PayPal/Zelle
            </div>
          </div>
        </div>
        <div className="tabContent">{renderContent()}</div>
      </div>
    </div>
  );
};

export default TabbedBox;
