import React, { useState, useEffect } from "react";
import "./HomeImage.css";

function HomeImage() {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Add the show class after the component is mounted
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 10); // Delay to ensure the transition is visible

    return () => clearTimeout(timer); // Clean up the timer
  }, []);
  return (
    <div>
      <div
        className={`backgroundImageH fade-in ${isVisible ? "show" : ""}`}
        src="https://images.pexels.com/photos/3589228/pexels-photo-3589228.jpeg"
        alt="home_image"
      ></div>
      <div className="overlayText">
        <h1>
          Educating foreign students in American Culture and the love of Christ.
        </h1>
        <p>
          Want to learn more? Check out some of our videos by clicking this
          button!
        </p>
        <a
          className={`ctaButton fade-in ${isVisible ? "show" : ""}`}
          href="/videos"
        >
          Our Videos
        </a>
      </div>
    </div>
  );
}

export default HomeImage;
