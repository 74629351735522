import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./ContactForm.css";

function ContactForm() {
  function sendEmail(firstName, lastName, msg, email) {
    emailjs
      .send(
        "service_sj08c5u",
        "template_kjloep3",
        {
          from_name: firstName + " " + lastName,
          message: msg,
          reply_to: email,
        },
        "vCiDVq8iUC7UWq7lr"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Your message has been sent!");
        },
        (error) => {
          console.log("FAILED...", error);
          alert("There was an error sending your message.");
        }
      );
  }

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted:", formData);
    sendEmail(
      formData.firstName,
      formData.lastName,
      formData.message,
      formData.email
    );
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      message: "",
    });
  };
  return (
    <div className="contentC">
      <div className="form-container">
        <h1>Use the form to send us an email!</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />
          <textarea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
