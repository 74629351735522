import React from "react";
import "./Navbar2.css";

function Navbar2() {
  return (
    <>
      <div className="navBox">
        <a className="navLinks" href="/">
          Home
        </a>
        <a className="navLinks" href="/videos">
          Videos
        </a>
        <a href="/">
          <img
            src="https://i.ibb.co/1J4shGq/logosdarkblue.png"
            className="logoBox"
            alt="logo"
          />
        </a>
        <a className="navLinks" href="/contact">
          Contact Us
        </a>
        <a className="navLinks" href="/donate">
          Donate
        </a>
      </div>
    </>
  );
}

export default Navbar2;
