import React from "react";
import "../../App.css";
import "../Title.css";
import DonateImage from "../DonateImage";
import DonateForm from "../DonateForm";

function Donate() {
  return (
    <>
      <DonateImage />
      <DonateForm />
    </>
  );
}

export default Donate;
