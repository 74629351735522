import React from "react";
import "../../App.css";
import "../Title.css";
import VideoImage from "../VideoImage";
import VideoNav from "../VideoNav";

function Videos() {
  return (
    <>
      <VideoImage />
      <VideoNav />
    </>
  );
}

export default Videos;
